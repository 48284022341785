import React, { useState } from "react"
// import axios from 'axios'
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import CustomBlogBanner from "../components/blog-banner"
import BlogCard from "../components/blog-card"
import { graphql } from "gatsby"
import Moment from "react-moment"
// import GraphImg from "graphcms-image"

const BlogHome = ({ data }) => {
  const [visiblePosts, setVisiblePosts] = useState(30)

  //eslint-disable-next-line
  const [numberOfPosts, setNumberOfPosts] = useState(data.gcms.blogPosts.length)

  const loadMore = () => {
    setVisiblePosts(prevState => prevState + 30)
  }

  return (
    <Layout>
      <SEO
        title="Blogs on subscription billing solutions | Billsby"
        description="Blogs on subscription billing solutions for fitness club business, travel business, real estate, and medical billing | Billsby"
        url="https://www.billsby.com/subscription-economy-blog"
      />

      <CustomBlogBanner />

      <div className="section-blog-list">
        <div className="container">
          <div className="list-wrapper">
            {
              data.gcms.blogPosts.slice(0, visiblePosts).map(post => {
                var url = post.slug
                var cat = post.blogCategory !== null ? post.blogCategory.categoryName : null
                var catSlug = post.blogCategory !== null ? post.blogCategory.slug : null

              return (
                <BlogCard
                  // featuredImage={post.featuredImage.url}
                  key={post.id}
                  image={post.image}
                  category={cat}
                  categorySlug={catSlug}
                  title={post.title}
                  url={url}
                  date={
                    <Moment format="MMMM DD YYYY">
                      {post.publicationDate}
                    </Moment>
                  }
                />
              )
            })}
          </div>
          <div className="btn-wrapper">
            {
              visiblePosts < numberOfPosts &&
              <button className="btn-blue" onClick={loadMore}>Load more posts</button>
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogHome

export const query = graphql`
  query BlogHomeTemplate {
    gcms {
      blogPosts(orderBy: publicationDate_DESC, stage: PUBLISHED) {
        id
        blogCategory {
          categoryName
          slug
        }
        title
        slug
        publicationDate
        image: featuredImage {
          handle
          width
          height
        }
      }
    }
  }
`
